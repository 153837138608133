// src/components/Signup.js
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from '../firebase';
import GoogleSignIn from './GoogleSignIn';
import './Signup.css';

function Signup() {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const handleSignup = async (e) => {
        e.preventDefault();
        if (!validateEmail(email)) {
            setEmailError('Invalid email address');
            return;
        }
        if (password !== confirmPassword) {
            setErrorMessage('Passwords do not match');
            return;
        }
        setEmailError('');
        setErrorMessage('');

        try {
            await createUserWithEmailAndPassword(auth, email, password);
            navigate('/create-campaign'); // Redirect to Create Campaign page
        } catch (error) {
            console.error('Signup error:', error);
            setErrorMessage(error.message);
        }
    };

    return (
        <div className="signup-container">
            <form className="signup-form" onSubmit={handleSignup}>
                <h2>Sign up</h2>
                <p>Create your account</p>
                {errorMessage && <p className="error">{errorMessage}</p>}
                <label>
                    <i className="fa fa-user" aria-hidden="true"></i>
                    <input type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} required />
                </label>
                <label>
                    <i className="fa fa-envelope" aria-hidden="true"></i>
                    <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                </label>
                {emailError && <p className="error">{emailError}</p>}
                <label>
                    <i className="fa fa-lock" aria-hidden="true"></i>
                    <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                </label>
                <label>
                    <i className="fa fa-lock" aria-hidden="true"></i>
                    <input type="password" placeholder="Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
                </label>
                <button type="submit">Sign up</button>
            </form>
            <GoogleSignIn /> {/* Add Google Sign-In button */}
            <div className="signup-footer">
                <p>Already have an account? <Link to="/login">Login</Link></p>
            </div>
        </div>
    );
}

export default Signup;
