import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './BlogDetails.css';

const BlogDetails = ({ blogs }) => {
    const { id } = useParams();
    const blog = blogs.find(blog => blog.id === parseInt(id));

    if (!blog) {
        return <h2>Blog not found</h2>;
    }

    return (
        <div className="blog-details">
            <Helmet>
                <title>{blog.title} - Vidzo Blog</title>
                <meta name="description" content={blog.summary} />
                {/* Add more meta tags as needed */}
            </Helmet>

            <h1>{blog.title}</h1>
            <img src={blog.image} alt={blog.title} className="blog-details-image" />
            <div className="blog-content" dangerouslySetInnerHTML={{ __html: blog.content }} />
        </div>
    );
};

export default BlogDetails;
