// src/components/Success.jsx
import React from 'react';
import { FaWhatsapp } from 'react-icons/fa'; // Import WhatsApp icon from react-icons
import './success.css'; // Import Success.css for styling

const Success = () => {
    return (
        <div className="success-container">
            <div className="success-content">
                <h1 className="animate__animated animate__bounceIn">Your Campaign Has Started!</h1>
                <p className="success-text">Congratulations on launching your campaign. You will receive updates via email.</p>
                <p className="success-text">If you have any questions or need assistance, feel free to contact us:</p>
                <a href="https://wa.me/+923116083445" target="_blank" rel="noopener noreferrer" className="contact-link">
                    <FaWhatsapp /> Contact Us on WhatsApp
                </a>
            </div>
        </div>
    );
};

export default Success;
