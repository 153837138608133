import React from 'react';
import './HowItWorksPage.css';
import { FaSearch, FaChartLine, FaBullhorn } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const HowItWorks = () => {
    return (
        <div>
            <header className="howitworks-hero">
                <div className="container">
                    <h1>How Vidzo Promotes Your Videos</h1>
                    <p>Discover the comprehensive process we use to boost your YouTube presence.</p>
                </div>
            </header>

            <section className="process">
                <div className="container">
                    <div className="step">
                        <FaSearch className="icon" />
                        <h2>Step 1: SEO Optimization</h2>
                        <p>We evaluate and optimize your video's SEO to improve search engine visibility. This includes keyword optimization, metadata enhancement, and content strategy recommendations.</p>
                    </div>
                    <div className="step">
                        <FaChartLine className="icon" />
                        <h2>Step 2: Niche Research</h2>
                        <p>We conduct thorough research into your video's niche to understand audience preferences and trends. This helps tailor our promotion strategies for maximum impact.</p>
                    </div>
                    <div className="step">
                        <FaBullhorn className="icon" />
                        <h2>Step 3: Promotion Strategies</h2>
                        <h3>Google Ads</h3>
                        <p>We run targeted Google Ads campaigns to drive relevant traffic to your videos efficiently.</p>
                        <h3>Social Media Sharing</h3>
                        <p>We share your videos across niche-specific groups and communities on platforms like Facebook and WhatsApp, leveraging targeted outreach for optimal results.</p>
                        <h3>Backlinking</h3>
                        <p>We secure backlinks from authoritative blogs and websites within your niche to enhance your video's credibility and search engine ranking.</p>
                        <h3>Creator Collaborations</h3>
                        <p>We collaborate with influential creators on platforms like Facebook, YouTube, and Instagram to amplify your video's reach and engagement.</p>
                        <p>We also promote your content through our own successful channels, using engaging embedding techniques and end video cards to attract a broader audience.</p>
                    </div>
                </div>
            </section>

            <section className="cta">
                <div className="container">
                    <h2>Ready to Promote your YouTube channel?</h2>
                    <p>Get Started with Vidzo Today!</p>
                    <Link to="/signup" className="cta-button">Get Started</Link>
                </div>
            </section>
        </div>
    );
};

export default HowItWorks;
