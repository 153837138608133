import React from 'react';
import { Helmet } from 'react-helmet';
import './Services.css';
import service1Image from '../assets/video-promotion.webp';
import service2Image from '../assets/seo.webp';
import service3Image from '../assets/influncers.webp';
import service4Image from '../assets/content-creator.webp';

const Services = () => {
    return (
        <div className="services">
            <Helmet>
                <title>Featured Services - Vidzo</title>
                <meta name="description" content="Explore Vidzo's featured services including video promotion, SEO optimization, influencer collaboration, and content creation. Maximize your video reach and engage your audience effectively." />
                {/* Add more meta tags as needed */}
            </Helmet>

            <h2>Featured Services</h2>
            <div className="service-items">
                <div className="service-item">
                    <img src={service1Image} alt="Video Promotion service" className="service-image" />
                    <div className="service-content">
                        <h3>Video Promotion</h3>
                        <p>Maximize your video reach with targeted campaigns.</p>
                    </div>
                </div>
                <div className="service-item">
                    <img src={service2Image} alt="SEO Optimization service" className="service-image" />
                    <div className="service-content">
                        <h3>SEO Optimization</h3>
                        <p>Boost your video’s visibility with expert SEO services.</p>
                    </div>
                </div>
                <div className="service-item">
                    <img src={service3Image} alt="Influencer Collaboration service" className="service-image" />
                    <div className="service-content">
                        <h3>Influencer Collaboration</h3>
                        <p>Partner with top influencers to expand your brand's reach.</p>
                    </div>
                </div>
                <div className="service-item">
                    <img src={service4Image} alt="Content Creation service" className="service-image" />
                    <div className="service-content">
                        <h3>Content Creation</h3>
                        <p>Engage your audience with high-quality content.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Services;
