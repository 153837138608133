import React from 'react';
import { Helmet } from 'react-helmet';
import './HowItWorks.css';
import { FaUserFriends, FaGoogle, FaShareAlt, FaLink } from 'react-icons/fa';

const HowItWorks = () => {
    return (
        <div className="how-it-works">
            <Helmet>
                <title>How It Works - Vidzo</title>
                <meta name="description" content="Learn how Vidzo's video promotion process maximizes reach through influencer marketing, Google Ads, social media sharing, and gaining backlinks." />
                {/* Add more meta tags as needed */}
            </Helmet>

            <h2>How It Works</h2>
            <p className="how-it-works-description">
                Our video promotion process is designed to maximize your reach and engagement.<br />
                We utilize several methods to ensure your videos get the visibility they deserve.<br />
                Here’s a brief overview of how we do it.
            </p>
            <div className="how-it-works-steps">
                <div className="step">
                    <FaUserFriends className="icon" />
                    <div className="step-content">
                        <h3>Influencer Marketing</h3>
                        <p>Promote your videos through top influencers in your niche to reach a wider audience.</p>
                    </div>
                </div>
                <div className="step">
                    <FaGoogle className="icon" />
                    <div className="step-content">
                        <h3>Google Ads</h3>
                        <p>Utilize Google Ads to target specific audiences and boost your video visibility.</p>
                    </div>
                </div>
                <div className="step">
                    <FaShareAlt className="icon" />
                    <div className="step-content">
                        <h3>Social Media Sharing</h3>
                        <p>Share your videos on various social media platforms to increase engagement and views.</p>
                    </div>
                </div>
                <div className="step">
                    <FaLink className="icon" />
                    <div className="step-content">
                        <h3>Backlinks</h3>
                        <p>Gain backlinks from niche-related blogs and channels to improve your video's SEO.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HowItWorks;
