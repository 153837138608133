// src/components/Login.js
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from '../firebase';
import GoogleSignIn from './GoogleSignIn';
import './Login.css';

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        setErrorMessage('');
        try {
            await signInWithEmailAndPassword(auth, username, password);
            navigate('/create-campaign'); // Redirect to Create Campaign page
        } catch (error) {
            console.error('Login error:', error);
            setErrorMessage(error.message);
            setLoading(false);
        }
    };

    return (
        <div className="login-container">
            <form className="login-form" onSubmit={handleLogin}>
                <h2>Login</h2>
                <p>Access your account</p>
                {errorMessage && <p className="error">{errorMessage}</p>}
                {loading && <p className="loading">Loading...</p>}
                <label>
                    <i className="fa fa-user" aria-hidden="true"></i>
                    <input type="text" placeholder="Email" value={username} onChange={(e) => setUsername(e.target.value)} required />
                </label>
                <label>
                    <i className="fa fa-lock" aria-hidden="true"></i>
                    <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                </label>
                <button type="submit" disabled={loading}>Login</button>
            </form>
            <GoogleSignIn /> {/* Add Google Sign-In button */}
            <div className="login-footer">
                <p>Don't have an account? <Link to="/signup">Sign Up</Link></p>
            </div>
        </div>
    );
}

export default Login;
