// src/components/GoogleSignIn.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, googleProvider } from '../firebase';
import { signInWithPopup } from 'firebase/auth';
import './GoogleSignIn.css'; // Ensure correct path

function GoogleSignIn() {
    const navigate = useNavigate();

    const handleGoogleSignIn = async () => {
        try {
            await signInWithPopup(auth, googleProvider);
            navigate('/create-campaign'); // Redirect to Create Campaign page
        } catch (error) {
            console.error('Google sign-in error:', error);
        }
    };

    return (
        <div className="google-login">
            <button className="google-login-button" onClick={handleGoogleSignIn}>
                <img className="google-logo" src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" alt="Google Logo" />
                <span className="google-button-text">Sign in with Google</span>
            </button>
        </div>
    );
}

export default GoogleSignIn;
