import React from 'react';
import { useNavigate } from 'react-router-dom';
import './CallToAction.css';

const CallToAction = () => {
    const navigate = useNavigate();

    const handleGetStartedClick = () => {
        navigate('/signup');
    };

    return (
        <div className="call-to-action">
            <h2>Ready to Boost Your Videos?</h2>
            <p>Join our platform and start promoting your videos with the best tools and services available.</p>
            <button className="cta-button" onClick={handleGetStartedClick}>Get Started Now</button>
        </div>
    );
};

export default CallToAction;
