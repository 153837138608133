import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy">
            <div className="privacy-policy-container">
                <h1>Privacy Policy</h1>
                <p>Your privacy is important to us. This privacy policy explains what personal data we collect and how we use it.</p>
                
                <div className="privacy-policy-section">
                    <h2>Information We Collect</h2>
                    <p>We collect various types of information in connection with the services we provide, including:</p>
                    <ul>
                        <li>Information you provide directly to us.</li>
                        <li>Information we collect automatically.</li>
                        <li>Information from third parties.</li>
                    </ul>
                </div>
                
                <div className="privacy-policy-section">
                    <h2>How We Use Information</h2>
                    <p>We use the information we collect for various purposes, including:</p>
                    <ul>
                        <li>To provide and improve our services.</li>
                        <li>To communicate with you.</li>
                        <li>To comply with legal obligations.</li>
                    </ul>
                </div>
                
                <div className="privacy-policy-section">
                    <h2>Sharing Information</h2>
                    <p>We do not share your personal information with third parties except as described in this policy.</p>
                </div>
                
                <div className="privacy-policy-section">
                    <h2>Data Security</h2>
                    <p>We implement security measures to protect your information from unauthorized access.</p>
                </div>
                
                <div className="privacy-policy-section">
                    <h2>Contact Us</h2>
                    <p>If you have any questions about this privacy policy, please contact us.</p>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
