import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import './Statistics.css';
import userIcon from '../assets/users.png'; // Ensure you have this image in the assets folder
import campaignsIcon from '../assets/createdcampaigns.webp'; // Ensure you have this image in the assets folder
import launchedIcon from '../assets/campaign-icon.webp'; // Ensure you have this image in the assets folder
import tutorialsIcon from '../assets/tutorials.png'; // Ensure you have this image in the assets folder

const Statistics = () => {
    const [users, setUsers] = useState(0);
    const [campaignsCreated, setCampaignsCreated] = useState(0);
    const [campaignsLaunched, setCampaignsLaunched] = useState(0);
    const [hasCounted, setHasCounted] = useState(false);

    const statRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            if (statRef.current) {
                const rect = statRef.current.getBoundingClientRect();
                if (rect.top < window.innerHeight && !hasCounted) {
                    setHasCounted(true);
                    startCounting();
                }
            }
        };

        const startCounting = () => {
            const userCount = 3193;
            const createdCount = 54000;
            const launchedCount = 60000;

            const duration = 2000;
            const incrementTime = 50;

            const increment = (setState, endValue) => {
                let current = 0;
                const step = Math.ceil(endValue / (duration / incrementTime));

                const timer = setInterval(() => {
                    current += step;
                    if (current >= endValue) {
                        current = endValue;
                        clearInterval(timer);
                    }
                    setState(current);
                }, incrementTime);
            };

            increment(setUsers, userCount);
            increment(setCampaignsCreated, createdCount);
            increment(setCampaignsLaunched, launchedCount);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [hasCounted]);

    return (
        <div className="statistics" ref={statRef}>
            <Helmet>
                <title>Statistics - Vidzo</title>
                <meta name="description" content="Statistics on engaged users, campaigns created, and campaigns launched by Vidzo. Learn more about how Vidzo optimizes content through tutorials." />
                {/* Add more meta tags as needed */}
            </Helmet>

            <div className="stat-item">
                <img src={userIcon} alt="Engaged users icon" className="stat-icon" />
                <div className="stat-text">
                    <h2>{users.toLocaleString()}</h2>
                    <p>Engaged users</p>
                </div>
            </div>
            <div className="stat-item">
                <img src={campaignsIcon} alt="Campaigns created icon" className="stat-icon" />
                <div className="stat-text">
                    <h2>{campaignsCreated.toLocaleString()}</h2>
                    <p>Campaigns created</p>
                </div>
            </div>
            <div className="stat-item">
                <img src={launchedIcon} alt="Campaigns launched icon" className="stat-icon" />
                <div className="stat-text">
                    <h2>{campaignsLaunched.toLocaleString()}</h2>
                    <p>Campaigns launched</p>
                </div>
            </div>
            <div className="stat-item">
                <img src={tutorialsIcon} alt="Tutorials icon for optimizing content" className="stat-icon" />
                <div className="stat-text">
                    <h2>Tutorials</h2>
                    <p>Optimize content</p>
                </div>
            </div>
        </div>
    );
};

export default Statistics;
