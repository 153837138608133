import React, { useState } from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';
import logo from '../assets/vidzo-logo.png'; // Adjust the path as necessary

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav className="navbar">
            <div className="navbar-container">
                <div className="logo">
                    <Link to="/" onClick={() => setIsOpen(false)}>
                        <img src={logo} alt="Vidzo Logo" style={{ height: '60px' }} />
                    </Link>
                </div>
                <ul className={`nav-links ${isOpen ? 'open' : ''}`}>
                    <li><Link to="/how-it-works" onClick={() => setIsOpen(false)}>How Vidzo Works</Link></li>
                    <li><Link to="/pricing" onClick={() => setIsOpen(false)}>Pricing</Link></li>
                    <li><Link to="/contact-us" onClick={() => setIsOpen(false)}>Contact Us</Link></li>
                    <li><Link to="/blog" onClick={() => setIsOpen(false)}>Blog</Link></li>
                </ul>
                <div className="auth-buttons">
                    <Link to="/login" onClick={() => setIsOpen(false)}>
                        <button className="signin">Login</button>
                    </Link>
                    <Link to="/signup" onClick={() => setIsOpen(false)}>
                        <button className="signup">Sign Up</button>
                    </Link>
                </div>
                <button
                    className={`toggle-button ${isOpen ? 'open' : ''}`}
                    onClick={toggleMenu}
                    aria-label="Toggle navigation menu"
                >
                    <span className="bar"></span>
                    <span className="bar"></span>
                    <span className="bar"></span>
                </button>
            </div>
        </nav>
    );
};

export default Navbar;
