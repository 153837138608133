import React from 'react';
import './Testimonials.css';
import user1Image from '../assets/user1-image.webp';
import user2Image from '../assets/user2-image.webp';
import user3Image from '../assets/user3-image.webp';

const Testimonials = () => {
    return (
        <div className="testimonials">
            <h2>What Our Users Say</h2>
            <div className="testimonial-items">
                <div className="testimonial-item">
                    <img src={user1Image} alt="User 1" className="testimonial-image" />
                    <div className="testimonial-content">
                        <h3>John Doe</h3>
                        <p>"This service has significantly boosted my video views. Highly recommend!"</p>
                    </div>
                </div>
                <div className="testimonial-item">
                    <img src={user2Image} alt="User 2" className="testimonial-image" />
                    <div className="testimonial-content">
                        <h3>Jane Smith</h3>
                        <p>"Amazing SEO optimization. My video ranks higher on search results now."</p>
                    </div>
                </div>
                <div className="testimonial-item">
                    <img src={user3Image} alt="User 3" className="testimonial-image" />
                    <div className="testimonial-content">
                        <h3>Mike Johnson</h3>
                        <p>"The influencer collaboration helped me reach a wider audience. Great experience!"</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Testimonials;
