import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './CreateCampaign.css';

const CreateCampaign = () => {
  const [videoUrl, setVideoUrl] = useState('');
  const [videoDetails, setVideoDetails] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    age_target: '',
    gender_target: '',
    interests_target: '',
    geographic_target: '',
    total_budget: '',
    daily_budget: ''
  });
  const navigate = useNavigate();
  const API_KEY = 'AIzaSyBOOj294sRMtUdsGd-tXsSwlA91YMlQnlU'; // Replace with your YouTube Data API key

  const extractVideoId = (url) => {
    try {
      const urlObj = new URL(url);
      return urlObj.searchParams.get('v');
    } catch (error) {
      console.error('Invalid URL:', error);
      return null;
    }
  };

  const fetchVideoDetails = async (url) => {
    const videoId = extractVideoId(url);
    if (!videoId) {
      throw new Error('Invalid video URL');
    }
    const apiUrl = `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=${API_KEY}&part=snippet`;

    try {
      const response = await axios.get(apiUrl);
      if (response.data.items.length === 0) {
        throw new Error('No video found for the provided URL');
      }
      const videoData = response.data.items[0].snippet;

      return {
        title: videoData.title,
        description: videoData.description,
        thumbnail: videoData.thumbnails.default.url,
      };
    } catch (error) {
      console.error('Error fetching video details:', error);
      throw error;
    }
  };

  const handleFetchVideoDetails = async () => {
    if (!videoUrl) {
      alert('Please enter a video URL.');
      return;
    }

    try {
      const details = await fetchVideoDetails(videoUrl);
      setVideoDetails(details);
    } catch (error) {
      console.error('Error fetching video details:', error);
      alert('Error fetching video details: ' + error.message);
    }
  };

  const validateForm = (data) => {
    const errors = {};
    if (!data.name) errors.name = 'Campaign Name is required';
    if (!data.video_url) errors.video_url = 'Video URL is required';
    if (!data.age_target) errors.age_target = 'Age range is required';
    if (!data.gender_target) errors.gender_target = 'Gender is required';
    if (!data.interests_target) errors.interests_target = 'Interests are required';
    if (!data.geographic_target) errors.geographic_target = 'Location is required';
    if (!data.total_budget || isNaN(data.total_budget)) errors.total_budget = 'Total Budget is required and must be a number';
    if (!data.daily_budget || isNaN(data.daily_budget)) errors.daily_budget = 'Daily Budget is required and must be a number';
    return errors;
  };

  const handleLaunchCampaign = async (e) => {
    e.preventDefault();
    if (!videoDetails) {
      alert('Please fetch video details first.');
      return;
    }

    const data = {
      name: formData.name,
      description: formData.description,
      video_url: videoUrl,
      age_target: formData.age_target,
      gender_target: formData.gender_target,
      interests_target: formData.interests_target,
      geographic_target: formData.geographic_target,
      total_budget: parseFloat(formData.total_budget),
      daily_budget: parseFloat(formData.daily_budget),
    };

    const errors = validateForm(data);
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      const token = localStorage.getItem('token');
      if (!token) {
        alert('You must be logged in to create a campaign.');
        return;
      }

      try {
        const response = await axios.post('https://ansarmehmoodd6767.pythonanywhere.com/api/campaigns/', data, {
          headers: {
            Authorization: `Token ${token}`,
            'Content-Type': 'application/json',
          },
        });
        console.log('Campaign created successfully:', response.data);
        navigate('/checkout', { state: { formData: data } });
      } catch (error) {
        console.error('Error creating campaign:', error.response ? error.response.data : error.message);
        alert('Error creating campaign: ' + (error.response ? error.response.data.detail : error.message));
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div className="campaign-setup-page">
      <div className="container">
        <h1>Campaign Details</h1>
        <form onSubmit={handleLaunchCampaign}>
          <input
            type="text"
            name="name"
            placeholder="Campaign Name"
            className={`input-field ${formErrors.name ? 'error' : ''}`}
            value={formData.name}
            onChange={handleInputChange}
            required
          />
          {formErrors.name && <span className="error-message">{formErrors.name}</span>}
          <input
            type="text"
            name="description"
            placeholder="Campaign Description (Optional)"
            className="input-field"
            value={formData.description}
            onChange={handleInputChange}
          />

          <h1>Video Upload</h1>
          <div className="video-upload">
            <input
              type="text"
              placeholder="Paste Video URL"
              className={`input-field ${formErrors.video_url ? 'error' : ''}`}
              value={videoUrl}
              onChange={(e) => setVideoUrl(e.target.value)}
              required
            />
            <button type="button" className="fetch-button" onClick={handleFetchVideoDetails}>
              Fetch Video Details
            </button>
          </div>
          {formErrors.video_url && <span className="error-message">{formErrors.video_url}</span>}
          {videoDetails && (
            <div className="video-preview">
              <img src={videoDetails.thumbnail} alt="Video Thumbnail" />
              <div className="video-info">
                <h2>{videoDetails.title}</h2>
                <p>{videoDetails.description}</p>
              </div>
            </div>
          )}

          <h1>Targeting Options</h1>
          <div className="targeting-options">
            <label>Age</label>
            <select name="age_target" className={`input-field ${formErrors.age_target ? 'error' : ''}`} value={formData.age_target} onChange={handleInputChange} required>
              <option value="">Select Age Range</option>
              <option value="5-10">5-10 years</option>
              <option value="10-20">10-20 years</option>
              <option value="15-35">15-35 years</option>
              <option value="20-40">20-40 years</option>
              <option value="18-65">18-65 years</option>
              <option value="18-70">18-70 years</option>
            </select>
            {formErrors.age_target && <span className="error-message">{formErrors.age_target}</span>}

            <label>Gender</label>
            <select name="gender_target" className={`input-field ${formErrors.gender_target ? 'error' : ''}`} value={formData.gender_target} onChange={handleInputChange} required>
              <option value="">Select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
            {formErrors.gender_target && <span className="error-message">{formErrors.gender_target}</span>}

            <label>Interests</label>
            <select name="interests_target" className={`input-field ${formErrors.interests_target ? 'error' : ''}`} value={formData.interests_target} onChange={handleInputChange} required>
              <option value="">Select Interests</option>
              <option value="technology">Technology</option>
              <option value="health">Health</option>
              <option value="music">Music</option>
              <option value="sports">Sports</option>
              <option value="travel">Travel</option>
              <option value="education">Education</option>
            </select>
            {formErrors.interests_target && <span className="error-message">{formErrors.interests_target}</span>}

            <label>Geographic Locations</label>
            <select name="geographic_target" className={`input-field ${formErrors.geographic_target ? 'error' : ''}`} value={formData.geographic_target} onChange={handleInputChange} required>
              <option value="">Select Location</option>
              <option value="USA">USA</option>
              <option value="Canada">Canada</option>
              <option value="UK">UK</option>
              <option value="Australia">Australia</option>
              <option value="India">India</option>
            </select>
            {formErrors.geographic_target && <span className="error-message">{formErrors.geographic_target}</span>}
          </div>

          <h1>Budget Allocation</h1>
          <input
            type="number"
            name="total_budget"
            placeholder="Total Budget ($)"
            className={`input-field ${formErrors.total_budget ? 'error' : ''}`}
            value={formData.total_budget}
            onChange={handleInputChange}
            required
          />
          {formErrors.total_budget && <span className="error-message">{formErrors.total_budget}</span>}
          <input
            type="number"
            name="daily_budget"
            placeholder="Daily Budget ($)"
            className={`input-field ${formErrors.daily_budget ? 'error' : ''}`}
            value={formData.daily_budget}
            onChange={handleInputChange}
            required
          />
          {formErrors.daily_budget && <span className="error-message">{formErrors.daily_budget}</span>}

          <h1>Preview</h1>
          {videoDetails && (
            <div className="preview">
              <div className="video-thumbnail">
                <img src={videoDetails.thumbnail} alt="Video Thumbnail" />
              </div>
              <div className="video-details">
                <h2>{videoDetails.title}</h2>
                <p>{videoDetails.description}</p>
                <p>Targeted Demographics and Locations</p>
              </div>
              <div className="campaign-summary">
                <h2>Campaign Summary</h2>
                <p><strong>Name:</strong> {formData.name}</p>
                <p><strong>Description:</strong> {formData.description}</p>
                <p><strong>Video URL:</strong> {videoUrl}</p>
                <p><strong>Age Range:</strong> {formData.age_target}</p>
                <p><strong>Gender:</strong> {formData.gender_target}</p>
                <p><strong>Interests:</strong> {formData.interests_target}</p>
                <p><strong>Geographic Target:</strong> {formData.geographic_target}</p>
                <p><strong>Total Budget:</strong> ${parseFloat(formData.total_budget)}</p>
                <p><strong>Daily Budget:</strong> ${parseFloat(formData.daily_budget)}</p>
              </div>
            </div>
          )}

          <button type="submit" className="launch-button">
            Save & Launch Campaign
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateCampaign;
