import React from 'react';
import './AboutUs.css';

const AboutUs = () => {
    return (
        <div className="about-us">
            <div className="about-us-container">
                <h1>About Us</h1>
                <p>We are dedicated to helping you boost your YouTube videos through modern promotion techniques, SEO optimization, influencer collaborations, and high-quality content creation.</p>
                <div className="about-us-section">
                    <h2>Our Mission</h2>
                    <p>Our mission is to provide comprehensive solutions to ensure your videos reach their target audience and achieve the desired impact.</p>
                </div>
                <div className="about-us-section">
                    <h2>Our Team</h2>
                    <p>Our team consists of experienced professionals in video marketing, SEO, and content creation, all committed to helping you succeed.</p>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;
