import React from 'react';
import './Footer.css';
import { FaFacebook, FaLinkedin, FaInstagram, FaYoutube } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import logo from '../assets/vidzo-logo.png'; // Adjust the path as necessary

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-section logo-section">
                    <Link to="/">
                        <img src={logo} alt="Vidzo Logo" className="footer-logo" />
                    </Link>
                </div>
                <div className="footer-section links">
                    <h2>Quick Links</h2>
                    <ul>
                        <li><Link to="/how-it-works">How It Works</Link></li>
                        <li><Link to="/contact-us">Contact Us</Link></li>
                        <li><Link to="/about-us">About Us</Link></li>
                        <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                        <li><Link to="/terms-of-use">Terms of Use</Link></li>
                    </ul>
                </div>
                <div className="footer-section social">
                    <h2>Follow Us</h2>
                    <div className="social-links">
                        <a href="https://www.facebook.com/profile.php?id=61562197947795" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
                        <a href="https://www.instagram.com/vidzo_promotion/" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
                        <a href="https://www.youtube.com/@Vidzo_Promotions" target="_blank" rel="noopener noreferrer"><FaYoutube /></a>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <p>&copy; 2024 Vidzo. All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
