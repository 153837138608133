// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Statistics from './components/Statistics';
import Services from './components/Services';
import HowItWorks from './components/HowItWorks';
import HowItWorksPage from './components/HowItWorksPage';
import Testimonials from './components/Testimonials';
import CallToAction from './components/CallToAction';
import Footer from './components/Footer';
import Pricing from './components/Pricing';
import CreateCampaign from './components/CreateCampaign';
import CheckoutPage from './components/CheckoutPage';
import ContactUs from './components/ContactUs';
import AboutUs from './components/AboutUs';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfUse from './components/TermsOfUse';
import Campaigns from './components/Campaigns';
import Signup from './components/Signup';
import Login from './components/Login';
import ExpertReviewPage from './components/ExpertReviewPage';
import MainPage, { dummyBlogs } from './components/MainPage';
import BlogDetails from './components/BlogDetails';
import Success from './components/success'; // Import Success component

function App() {
    return (
        <Router>
            <div className="App">
                <Navbar />
                <Routes>
                    <Route path="/" element={
                        <>
                            <Hero />
                            <Statistics />
                            <Services />
                            <HowItWorks />
                            <Testimonials />
                            <CallToAction />
                        </>
                    } />
                    <Route path="/pricing" element={<Pricing />} />
                    <Route path="/create-campaign" element={<CreateCampaign />} />
                    <Route path="/checkout" element={<CheckoutPage />} />
                    <Route path="/contact-us" element={<ContactUs />} />
                    <Route path="/how-it-works" element={<HowItWorksPage />} />
                    <Route path="/about-us" element={<AboutUs />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/terms-of-use" element={<TermsOfUse />} />
                    <Route path="/campaigns" element={<Campaigns />} />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/expert-review" element={<ExpertReviewPage />} />
                    <Route path="/blog" element={<MainPage />} />
                    <Route path="/blog/:id" element={<BlogDetails blogs={dummyBlogs} />} />
                    <Route path="/success" element={<Success />} /> {/* Add this route for Success component */}
                </Routes>
                <Footer />
            </div>
        </Router>
    );
}

export default App;
