import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './Blog.css';

const Blog = ({ id, title, summary, image }) => {
    return (
        <div className="blog-container">
            <Helmet>
                <title>{title} - Vidzo Blog</title>
                <meta name="description" content={summary} />
                {/* Add more meta tags as needed */}
            </Helmet>

            <img src={image} alt={title} className="blog-image" />
            <h2>{title}</h2>
            <p>{summary}</p>
            <Link to={`/blog/${id}`}>
                <button>Read More</button>
            </Link>
        </div>
    );
};

export default Blog;
