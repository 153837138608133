// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDTepGEDCXaNwHk7a8-gdKlV9qqmKKxGlM",
  authDomain: "vidzo-7cf80.firebaseapp.com",
  projectId: "vidzo-7cf80",
  storageBucket: "vidzo-7cf80.appspot.com",
  messagingSenderId: "862162138425",
  appId: "1:862162138425:web:12ce7cbc3530d192c724e8",
  measurementId: "G-FHTS4EX2T9"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();

export { app, analytics, auth, db, googleProvider };
