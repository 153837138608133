import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import './Hero.css';
import heroImage from '../assets/Hero-image.png';

const Hero = () => {
    const navigate = useNavigate();

    const handleGetExpertReview = () => {
        navigate('/expert-review');
    };

    return (
        <div className="hero">
            <Helmet>
                <title>Vidzo - Video Promotion Solutions</title>
                <meta name="description" content="Vidzo promotes your videos with SEO optimization, targeted promotion, influencer collaborations, and content creation." />
                {/* Add more meta tags as needed */}
            </Helmet>

            <div className="hero-content">
                <h1>Vidzo Promotes Your Videos in a Modern Style To Achieve Your Specific Goals</h1>
                <p className="subheading">
                    We provide comprehensive solutions including SEO optimization, targeted promotion, influencer collaborations, and content creation.
                </p>
                <div className="hero-buttons">
                    <Link to="/signup">
                        <button className="primary-btn">Get Started</button>
                    </Link>
                    <button className="secondary-btn" onClick={handleGetExpertReview}>Get Expert Review</button>
                </div>
            </div>
            <div className="hero-image">
                <img src={heroImage} alt="Hero promoting video content" />
            </div>
        </div>
    );
};

export default Hero;
