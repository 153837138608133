import React from 'react';
import './TermsOfUse.css';

const TermsOfUse = () => {
    return (
        <div className="terms-of-use">
            <div className="terms-of-use-container">
                <h1>Terms of Use</h1>
                <p>Welcome to our website. These terms of use outline the rules and regulations for the use of our website.</p>
                
                <div className="terms-of-use-section">
                    <h2>Introduction</h2>
                    <p>By accessing this website, we assume you accept these terms of use in full. Do not continue to use our website if you do not accept all of the terms of use stated on this page.</p>
                </div>
                
                <div className="terms-of-use-section">
                    <h2>License</h2>
                    <p>Unless otherwise stated, we own the intellectual property rights for all material on the website. All intellectual property rights are reserved.</p>
                </div>
                
                <div className="terms-of-use-section">
                    <h2>Restrictions</h2>
                    <p>You are specifically restricted from all of the following:</p>
                    <ul>
                        <li>Publishing any website material in any other media.</li>
                        <li>Selling, sublicensing, and/or otherwise commercializing any website material.</li>
                        <li>Using this website in any way that is or may be damaging to this website.</li>
                    </ul>
                </div>
                
                <div className="terms-of-use-section">
                    <h2>Limitation of Liability</h2>
                    <p>In no event shall we, nor any of our officers, directors, and employees, be held liable for anything arising out of or in any way connected with your use of this website.</p>
                </div>
                
                <div className="terms-of-use-section">
                    <h2>Contact Us</h2>
                    <p>If you have any questions about these terms of use, please contact us.</p>
                </div>
            </div>
        </div>
    );
};

export default TermsOfUse;
