import React, { useState } from 'react';
import './ExpertReviewPage.css';

const ExpertReviewPage = () => {
  const [channelUrl, setChannelUrl] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic URL validation
    if (!isValidUrl(channelUrl)) {
      setError('Please enter a valid channel URL.');
      return;
    }

    setIsLoading(true);

    // Simulate expert review process (replace with actual logic)
    try {
      // Simulate async process (e.g., API call)
      await simulateExpertReview(channelUrl);

      setShowMessage(true);
      setError('');
    } catch (error) {
      setError('An error occurred. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  // Function to simulate expert review (replace with actual API call)
  const simulateExpertReview = (url) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        // Simulate success
        resolve();
        // Simulate error
        // reject(new Error('Failed to process'));
      }, 2000); // Simulate 2 seconds delay
    });
  };

  // Function to validate URL
  const isValidUrl = (url) => {
    // Basic URL pattern validation
    const pattern = /^(ftp|http|https):\/\/[^ "]+$/;
    return pattern.test(url);
  };

  return (
    <div className="expert-review-page">
      <div className="expert-review-banner">
        <h1>Accelerate your video growth with VidLaunch</h1>
        <p>Get more views, subscribers, and engagement on YouTube. Whether you're a creator or a brand, we'll help you grow.</p>
        <form onSubmit={handleSubmit} className="channel-form">
          <input
            type="url"
            placeholder="Enter your channel URL"
            value={channelUrl}
            onChange={(e) => setChannelUrl(e.target.value)}
            required
          />
          <button type="submit" disabled={isLoading}>Get started</button>
        </form>
        {isLoading && <p>Loading...</p>}
        {error && <p className="error-message">{error}</p>}
      </div>
      {showMessage && (
        <div className="expert-review-message">
          <p>OK, we are looking into it. Please wait, one of our experts will be in touch soon.</p>
        </div>
      )}
    </div>
  );
};

export default ExpertReviewPage;
