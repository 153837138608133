import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';

function CheckoutPage() {
  const navigate = useNavigate();

  const createOrder = (data, actions) => {
    // Logic to create the order on your server
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: '10.00', // Replace with dynamically calculated amount
          },
        },
      ],
      application_context: {
        return_url: `${window.location.origin}/success`, // Redirect to your success page
      },
    });
  };

  const onApprove = (data, actions) => {
    // Capture the funds from the transaction
    return actions.order.capture().then(function (details) {
      // Handle the successful payment
      console.log('Payment completed successfully:', details);
      navigate('/success'); // Redirect to success page
    });
  };

  const onError = (err) => {
    // Handle errors
    console.error('PayPal error:', err);
    // Optionally, display an error message to the user
    // For example:
    // alert('There was an error processing your payment. Please try again later.');
  };

  return (
    <div className="checkout-page">
      <h2>Checkout Page</h2>
      <PayPalScriptProvider options={{ 'client-id': 'AX8KUKge2Wf4sHeXR4n59j204nZnF-kf4vgyQ24qTVoJkc5L78_xcgphDeBrm8T5158p48_OcS0wkwPG' }}>
        <PayPalButtons
          createOrder={(data, actions) => createOrder(data, actions)}
          onApprove={(data, actions) => onApprove(data, actions)}
          onError={onError}
        />
      </PayPalScriptProvider>
    </div>
  );
}

export default CheckoutPage;
