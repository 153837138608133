// src/components/BlogList.js
import React from 'react';
import Blog from './Blog';

const BlogList = ({ blogs }) => {
  return (
    <div className="blog-list">
      {blogs.map((blog) => (
        <Blog
          key={blog.id}
          id={blog.id}
          title={blog.title}
          summary={blog.summary}
          image={blog.image}
        />
      ))}
    </div>
  );
};

export default BlogList;
