// src/components/Pricing.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Pricing.css';

const Pricing = () => {
  const [budget, setBudget] = useState(0);
  const [views, setViews] = useState(0);
  const [subscribers, setSubscribers] = useState(0);
  const [likes, setLikes] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const calculateEstimate = () => {
    if (budget < 30) {
      setErrorMessage('The minimum budget is $30.');
      return;
    }
    setViews(budget * 129.1);
    setSubscribers(budget * 4.8);
    setLikes(budget * 14.3);
    setErrorMessage('');
  };

  const proceedToCreateCampaign = () => {
    if (budget >= 30) {
      navigate('/create-campaign');
    } else {
      setErrorMessage('The minimum budget is $30.');
    }
  };

  return (
    <section id="pricing" className="section pricing">
      <div className="container">
        <div className="pricing-header">
          <h1>Pricing</h1>
        </div>
        <p>Gain insights into the expected performance of your promotion with our estimation tool, based on historical data from Stimax's past successes.</p>
        <div className="estimator">
          <div className="budget-input">
            <label htmlFor="budget">Enter Your Budget</label>
            <input
              type="number"
              id="budget"
              placeholder="$ Budget"
              min="0"
              value={budget}
              onChange={(e) => setBudget(e.target.value)}
            />
            <button onClick={calculateEstimate} className="btn">Get Estimate</button>
            <p id="error-message" className="error-message">{errorMessage}</p>
          </div>
          <div className="estimate-results">
            <h3>Your Estimates</h3>
            <div className="estimate">
              <div className="estimate-item">
                <h4>Views</h4>
                <p id="views">{views}</p>
              </div>
              <div className="estimate-item">
                <h4>Subscribers</h4>
                <p id="subscribers">{subscribers}</p>
              </div>
              <div className="estimate-item">
                <h4>Likes</h4>
                <p id="likes">{likes}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="proceed-button">
          <button className="btn proceed-btn" onClick={proceedToCreateCampaign}>Proceed</button>
        </div>
      </div>
    </section>
  );
};

export default Pricing;
